import type { Ref } from "react";
import type { InputProps } from "./Input.types";
import { forwardRef } from "react";
import InputBase from "~/components/InputBase";

const Input = forwardRef(function InputWithRef(
  {
    id,
    name,
    label,
    comment,
    type = "text",
    error,
    disabled = false,
    className,
    required,
    ...props
  }: InputProps,
  ref: Ref<HTMLInputElement>
) {
  return (
    <InputBase
      id={id || name}
      {...{ className, disabled, error, label, comment, required }}
    >
      <input
        className="InputBase-field"
        disabled={disabled}
        type={type}
        id={id || name}
        name={name}
        ref={ref}
        {...props}
      />
    </InputBase>
  );
});

export default Input;
